import { render, staticRenderFns } from "./MainTabs.vue?vue&type=template&id=4405ac7d&scoped=true&"
import script from "./MainTabs.vue?vue&type=script&lang=js&"
export * from "./MainTabs.vue?vue&type=script&lang=js&"
import style0 from "./MainTabs.vue?vue&type=style&index=0&id=4405ac7d&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4405ac7d",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VSheet } from 'vuetify/lib/components/VSheet';
import { VSnackbar } from 'vuetify/lib/components/VSnackbar';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';
installComponents(component, {VAppBar,VBtn,VCard,VCardActions,VCardText,VContainer,VDialog,VIcon,VImg,VSheet,VSnackbar,VSpacer,VTab,VTabItem,VTabs,VToolbarTitle})
