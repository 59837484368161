<template>
    <v-sheet id="scrolling-techniques-7" class="overflow-y-auto">
        <v-container>
            <v-tabs center-active v-model="activeTab" @change="getTripsSelectedTab">
                <v-tab>{{ $t('TripsList') }}</v-tab>
                <v-tab> {{ $t('SchedulesList') }}</v-tab>
                <v-tab-item>
                    <v-container class="mt-4">
                        <div id="routes-list" class="mt-8">
                            <div class="d-flex">
                                <!-- search -->
                                <v-text-field
                                    expanded
                                    :placeholder="$t('Search')"
                                    outlined
                                    hide-details
                                    dense
                                    :prepend-inner-icon="icons.mdiMagnify"
                                    class="user-search me-3 mb-4"
                                    @change="searchTrips()"
                                    v-model="searchTripsQuery">
                                </v-text-field>

                                <v-btn @click.stop="getTripFilter" outlined color="grey-light">
                                    <v-icon color="grey">
                                        {{ icons.mdiFilterVariant }}
                                    </v-icon>
                                </v-btn>
                            </div>

                            <v-data-table
                                show-select
                                calculate-widths
                                :items-per-page="tripOptions.itemsPerPage"
                                :footer-props="{
                                    'items-per-page-options': [5, 10, 15, 20],
                                    'items-per-page-text': $t('RowsPerPage'),
                                }"
                                :header-props="{ 'sort-by-text': $t('SortBy') }"
                                :headers="tripsTableColumns"
                                :items="tripListTable"
                                :server-items-length="totalTripListTable"
                                v-model="selectedTrips"
                                :loading="isLoading"
                                :options.sync="tripOptions"
                                @input="getTripsSelected"
                                @update:page="tripPaginationHandle"
                                height="400"
                                fixed-header>
                                <template #[`item.short_name`]="{ item }">
                                    <div class="extend-column d-flex flex-column ms-3">
                                        <router-link
                                            style="max-width: 12rem"
                                            :to="{ name: 'TripProfile', params: { id: item.id } }"
                                            class="text--primary font-weight-semibold text-truncate cursor-pointer text-decoration-none">
                                            {{ item.short_name }}
                                        </router-link>
                                    </div>
                                </template>

                                <template #[`item.bikes_allowed`]="{ item }">
                                    <span class="text--primary">
                                        {{ item.bikes_allowed }} - {{ getBikesInformation(item.bikes_allowed) }}
                                    </span>
                                </template>
                                <template #[`item.wheelchair_accessible`]="{ item }">
                                    <span class="text--primary">
                                        {{ item.wheelchair_accessible }} -
                                        {{ getWheelchairInformation(item.wheelchair_accessible) }}
                                    </span>
                                </template>
                                <template #[`item.actions`]="{ item }">
                                    <v-btn @click="$router.push({ name: 'TripProfile', params: { id: item.id } })" icon>
                                        <v-icon large>{{ icons.mdiChevronRight }}</v-icon>
                                    </v-btn>
                                </template>
                            </v-data-table>
                        </div>
                    </v-container>
                </v-tab-item>
                <v-tab-item>
                    <v-container class="mt-4">
                        <div id="routes-list" class="mt-8">
                            <div class="d-flex">
                                <!-- search -->
                                <v-text-field
                                    expanded
                                    :placeholder="$t('Search')"
                                    outlined
                                    hide-details
                                    dense
                                    :prepend-inner-icon="icons.mdiMagnify"
                                    @change="searchStopTimes()"
                                    v-model="searchStopTimesQuery"
                                    class="user-search me-3 mb-4">
                                </v-text-field>

                                <v-btn @click.stop="getStopTimeFilter" outlined color="grey-light">
                                    <v-icon color="grey">
                                        {{ icons.mdiFilterVariant }}
                                    </v-icon>
                                </v-btn>
                            </div>

                            <v-data-table
                                show-select
                                calculate-widths
                                :items-per-page="5"
                                :footer-props="{
                                    'items-per-page-options': [5, 10, 15, 20],
                                    'items-per-page-text': $t('RowsPerPage'),
                                }"
                                :header-props="{ 'sort-by-text': $t('SortBy') }"
                                :headers="stopsTableColumns"
                                :items="stopListTable"
                                :server-items-length="totalStopListTable"
                                @input="getStopTimesSelected"
                                v-model="selectedStopTimes"
                                @update:page="stopTimesPaginationHandle"
                                :options.sync="stopTimesOptions"
                                :loading="isLoading"
                                fixed-header
                                height="400">
                                <template #[`item.departure_time`]="{ item }">
                                    <div class="extend-column d-flex flex-column ms-3">
                                        <router-link
                                            style="max-width: 12rem"
                                            :to="{ name: 'StopTimesProfile', params: { id: item.id } }"
                                            class="text--primary font-weight-semibold text-truncate cursor-pointer text-decoration-none">
                                            {{ item.departure_time }}
                                        </router-link>
                                    </div>
                                </template>
                                <template #[`item.arrival_time`]="{ item }">
                                    <div class="extend-column d-flex flex-column ms-3">
                                        <router-link
                                            style="max-width: 12rem"
                                            :to="{ name: 'StopTimesProfile', params: { id: item.id } }"
                                            class="text--primary font-weight-semibold text-truncate cursor-pointer text-decoration-none">
                                            {{ item.arrival_time }}
                                        </router-link>
                                    </div>
                                </template>
                                <template #[`item.stop.name`]="{ item }">
                                    <div class="extend-column d-flex flex-column ms-3">
                                        <span class="text--primary">
                                            {{ item.stop.name }}
                                            <span v-if="item.stop.city">({{ item.stop.city }})</span>
                                        </span>
                                    </div>
                                </template>
                                <template #[`item.pickup_type`]="{ item }">
                                    <span class="text--primary">
                                        {{ item.pickup_type }} - {{ getPickUpInformation(item.pickup_type) }}
                                    </span>
                                </template>
                                <template #[`item.drop_off_type`]="{ item }">
                                    <span class="text--primary">
                                        {{ item.drop_off_type }} -
                                        {{ getDropOffInformation(item.drop_off_type) }}
                                    </span>
                                </template>
                                <template #[`item.actions`]="{ item }">
                                    <v-btn
                                        @click="$router.push({ name: 'StopTimesProfile', params: { id: item.id } })"
                                        icon>
                                        <v-icon large>{{ icons.mdiChevronRight }}</v-icon>
                                    </v-btn>
                                </template>
                            </v-data-table>
                        </div>
                    </v-container>
                </v-tab-item>
            </v-tabs>
        </v-container>
    </v-sheet>
</template>

<script>
    import useTripsList from './useTripsList';
    import useStopTimesList from './useStopTimesList';
    import CalendarList from '@/views/route/calendar/CalendarList.vue';
    import { mdiFilterVariant, mdiMagnify, mdiChevronRight } from '@mdi/js';
    export default {
        props: {
            tripsDeletedFinished: {
                type: Boolean,
            },
            fetchFilteredTrips: {
                type: Boolean,
            },
            stopTimesDeletedFinished: {
                type: Boolean,
            },
            fetchFilteredStopTimes: {
                type: Boolean,
            },
            calendarsDeletedFinished: {
                type: Boolean,
            },
            fetchFilteredCalendars: {
                type: Boolean,
            },
            calendarsDateDeletedFinished: {
                type: Boolean,
            },
            fetchFilteredCalendarDates: {
                type: Boolean,
            },
        },
        setup() {
            const { tripsTableColumns, tripListTable, totalTripListTable, fetchTripList } = useTripsList();

            const { stopsTableColumns, stopListTable, totalStopListTable, fecthStopTimesList } = useStopTimesList();

            return {
                tripsTableColumns,
                tripListTable,
                totalTripListTable,
                totalStopListTable,
                stopsTableColumns,
                stopListTable,
                fetchTripList,
                fecthStopTimesList,

                icons: {
                    mdiMagnify,
                    mdiFilterVariant,
                    mdiChevronRight,
                },
            };
        },

        data() {
            return {
                selectedTrips: [],
                selectedStopTimes: [],
                activeTab: 0,
                searchTripsQuery: null,
                searchStopTimesQuery: null,
                isLoading: false,
                tripQuery: {},
                stopTimesQuery: {},
                tripOptions: {
                    page: 1,
                },
                stopTimesOptions: {
                    page: 1,
                },
                calendarActiveTab: 0,
            };
        },

        components: {
            CalendarList,
        },

        created() {
            this.isLoading = true;
            this.$store.commit('app-routes/resetTripsQuery');
            this.$store.commit('app-routes/resetStopTimesQuery');
            this.tripQuery = this.$store.getters['app-routes/getTripsQuery'];
            this.stopTimesQuery = this.$store.getters['app-routes/getStopTimesQuery'];
            this.fetchTripList();
            this.fecthStopTimesList();
            setTimeout(() => {
                this.isLoading = false;
            }, 3000);
        },

        methods: {
            getWheelchairInformation(type) {
                switch (type) {
                    case 'O0':
                        return this.$t('NoInfoWheelchair');
                    case 'O1':
                        return this.$t('WheelchairAllowedInfo');
                    case 'O2':
                        return this.$t('WheelchairNotAllowedInfo');

                    default:
                        break;
                }
            },

            getPickUpInformation(type) {
                switch (type) {
                    case 'O0':
                        return this.$t('RegularPickup');
                    case 'O1':
                        return this.$t('NoPickupAvailable');
                    case 'O2':
                        return this.$t('PhoneAgencyPickup');
                    case 'O3':
                        return this.$t('PhoneDriverPickup');

                    default:
                        break;
                }
            },
            getDropOffInformation(type) {
                switch (type) {
                    case 'O0':
                        return this.$t('RegularDropOff');
                    case 'O1':
                        return this.$t('NoDropOffAvailable');
                    case 'O2':
                        return this.$t('PhoneAgencyDropOff');
                    case 'O3':
                        return this.$t('PhoneDriverDropOff');

                    default:
                        break;
                }
            },

            getTripsSelected() {
                this.$emit('getTripsSelected', this.selectedTrips);
            },

            getStopTimesSelected() {
                this.$emit('getStopTimesSelected', this.selectedStopTimes);
            },

            getTripsSelectedTab() {
                this.$emit('getTripsSelectedTab', this.activeTab);
            },

            getTripFilter() {
                this.$emit('getTripFilter', true);
            },

            getStopTimeFilter() {
                this.$emit('getStopTimeFilter', true);
            },

            getCalendarsSelected(calendars) {
                this.$emit('getCalendarsSelected', calendars);
            },

            getCalendarDatesSelected(calendarDates) {
                this.$emit('getCalendarDatesSelected', calendarDates);
            },

            getCalendarSelectedTab(val) {
                this.$emit('getCalendarSelectedTab', val);
            },

            searchTrips() {
                this.isLoading = true;
                this.$store.commit('app-routes/setTripSearch', this.searchTripsQuery);
                this.tripQuery = this.$store.getters['app-routes/getTripsQuery'];
                this.tripQuery.pagination.page = 1;
                this.fetchTripList();
                setTimeout(() => {
                    this.isLoading = false;
                }, 1000);
            },

            searchStopTimes() {
                this.isLoading = true;
                this.$store.commit('app-routes/setStopTimesSearch', this.searchStopTimesQuery);
                this.stopTimesQuery = this.$store.getters['app-routes/getStopTimesQuery'];
                this.stopTimesQuery.pagination.page = 1;
                this.fecthStopTimesList();
                setTimeout(() => {
                    this.isLoading = false;
                }, 1000);
            },

            tripPaginationHandle(e) {
                if (this.tripOptions.page > this.tripQuery.pagination.page) {
                    this.tripQuery.pagination.page = this.tripOptions.page;
                    this.tripQuery.pagination.pageSize = this.tripOptions.itemsPerPage;
                    this.$store.commit('app-routes/setTripsQuery', this.tripQuery);
                    this.fetchTripList();
                }
            },

            stopTimesPaginationHandle(e) {
                if (this.stopTimesOptions.page > this.stopTimesQuery.pagination.page) {
                    this.stopTimesQuery.pagination.page = this.stopTimesOptions.page;
                    this.$store.commit('app-routes/setStopTimesQuery', this.stopTimesQuery);
                    this.fecthStopTimesList();
                }
            },

            getCalendarFilter(bol) {
                this.$emit('getCalendarFilter', bol);
            },

            getCalendarDatesFilter(bol) {
                this.$emit('getCalendarDatesFilter', bol);
            },
        },

        watch: {
            tripOptions: {
                handler() {
                    this.tripQuery.pagination.page = this.tripOptions.page;
                    this.tripQuery.pagination.pageSize = this.tripOptions.itemsPerPage;
                    if (this.tripOptions.sortBy) {
                        this.tripQuery.sort = [
                            `${this.tripOptions.sortBy}:${this.tripOptions.sortDesc[0] ? 'desc' : 'asc'}`,
                        ];
                    }
                    if (this.tripOptions.sortBy.length === 0) {
                        this.tripQuery.sort = ['id:asc'];
                    }
                    this.$store.commit('app-routes/setTripsQuery', this.tripQuery);
                    this.fetchTripList();
                },
                deep: true,
            },
            tripsDeletedFinished: function (newVal, oldVal) {
                if (newVal == true) {
                    this.tripQuery = this.$store.getters['app-routes/getTripsQuery'];
                    this.fetchTripList();
                }
            },

            fetchFilteredTrips: function (newVal, oldVal) {
                if (newVal == true) {
                    this.isLoading = true;
                    this.fetchTripList();
                    setTimeout(() => {
                        this.isLoading = false;
                    }, 3000);
                }
            },

            stopTimesDeletedFinished: function (newVal, oldVal) {
                if (newVal == true) {
                    this.searchTripsQuery = this.$store.getters['app-routes/getStopTimesQuery'];
                    this.fecthStopTimesList();
                }
            },

            fetchFilteredStopTimes: function (newVal, oldVal) {
                if (newVal == true) {
                    this.isLoading = true;
                    this.fecthStopTimesList();
                    setTimeout(() => {
                        this.isLoading = false;
                    }, 3000);
                }
            },
        },

        mounted() {
            if (this.$route.params.tripTab !== undefined) {
                this.activeTab = this.$route.params.tripTab;
                this.$emit('getMainActiveTab', this.$route.params.tab);

                if (this.$route.params.calendarTab !== undefined) {
                    this.calendarActiveTab = this.$route.params.calendarTab;
                }
            }
            this.getTripsSelectedTab();
        },
    };
</script>

<style lang="scss" scoped>
    .v-application.theme--light .v-tabs:not(.v-tabs--vertical) {
        box-shadow: 0 0px 0px 0px rgb(94 86 105 / 42%) !important;
    }
</style>
