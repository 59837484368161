<template>
    <section class="section">
        <v-sheet id="scrolling-techniques-7" class="overflow-y-auto">
            <v-container class="mt-10">
                <RouteFilters @setQuery="getRoutesList" v-model="isRouteFiltersActive" />
                <TripFilters
                    @setQuery="getTripsList"
                    v-model="isTripFiltersActive"
                    v-if="tripsActiveTab == 0 && isTripFiltersActive" />
                <StopTimesFilters
                    @setQuery="getStopTimesList"
                    v-model="isStopTimeFiltersActive"
                    v-if="tripsActiveTab == 1 && isStopTimeFiltersActive" />

                <CalendarsFilters
                    @setQuery="getCalendarsList"
                    v-model="isCalendarFiltersActive"
                    v-if="tripsActiveTab == 2 && calendarActiveTab == 0 && isCalendarFiltersActive" />

                <CalendarDatesFilters
                    @setQuery="getCalendarDatesList"
                    v-model="isCalendarDatesFiltersActive"
                    v-if="tripsActiveTab == 2 && calendarActiveTab == 1 && isCalendarDatesFiltersActive" />
                <v-tabs center-active v-model="activeTab">
                    <v-tab>{{ $t('Route') }}</v-tab>
                    <v-tab>{{ $t('Trips') }}</v-tab>
                    <!--Routes and Trips components where is passed props and emit functions -->
                    <v-tab-item>
                        <RouteList
                            @getRoutesSelected="getRoutesSelected"
                            :routesDeletedFinished="routesDeletedFinished"
                            @getRouteFilter="getRouteFilter"
                            :fetchFilteredRoutes="fetchFilteredRoutes"
                            @getMainActiveTab="getMainActiveTab" />
                    </v-tab-item>
                    <v-tab-item
                        ><TripList
                            @getTripsSelected="getTripsSelected"
                            :tripsDeletedFinished="tripsDeletedFinished"
                            @getTripsSelectedTab="getTripsSelectedTab"
                            @getTripFilter="getTripFilter"
                            :fetchFilteredTrips="fetchFilteredTrips"
                            @getStopTimesSelected="getStopTimesSelected"
                            :stopTimesDeletedFinished="stopTimesDeletedFinished"
                            @getStopTimeFilter="getStopTimeFilter"
                            :fetchFilteredStopTimes="fetchFilteredStopTimes"
                            @getCalendarsSelected="getCalendarsSelected"
                            :calendarsDeletedFinished="calendarsDeletedFinished"
                            @getCalendarFilter="getCalendarFilter"
                            :fetchFilteredCalendars="fetchFilteredCalendars"
                            @getCalendarSelectedTab="getCalendarSelectedTab"
                            @getCalendarDatesSelected="getCalendarDatesSelected"
                            :calendarsDateDeletedFinished="calendarsDateDeletedFinished"
                            @getCalendarDatesFilter="getCalendarDatesFilter"
                            :fetchFilteredCalendarDates="fetchFilteredCalendarDates"
                            @getMainActiveTab="getMainActiveTab"
                    /></v-tab-item>
                </v-tabs>
            </v-container>

            <v-snackbar :timeout="2000" top v-model="isDeleteRouteSuccess" color="white">
                <v-img class="mx-auto trashcan" width="80" src="@/assets/icons/correct.png"></v-img>
                <p class="text-center title font-weight-semibold black--text mt-5">{{ $t('Alerts.Success') }}</p>
                <p class="text-center black--text">{{ $t('Alerts.DeleteRouteSueccess') }}</p>
            </v-snackbar>
            <v-snackbar :timeout="2000" top v-model="isDeleteTripSuccess" color="white">
                <v-img class="mx-auto trashcan" width="80" src="@/assets/icons/correct.png"></v-img>
                <p class="text-center title font-weight-semibold black--text mt-5">{{ $t('Alerts.Success') }}</p>
                <p class="text-center black--text">{{ $t('Alerts.DeleteRouteSueccess') }}</p>
            </v-snackbar>
            <v-snackbar :timeout="2000" top v-model="isDeleteStopTimeSuccess" color="white">
                <v-img class="mx-auto trashcan" width="80" src="@/assets/icons/correct.png"></v-img>
                <p class="text-center title font-weight-semibold black--text mt-5">{{ $t('Alerts.Success') }}</p>
                <p class="text-center black--text">{{ $t('Alerts.DeleteStopTimeSuccess') }}</p>
            </v-snackbar>
            <v-snackbar :timeout="2000" top v-model="isDeleteCalendarSuccess" color="white">
                <v-img class="mx-auto trashcan" width="80" src="@/assets/icons/correct.png"></v-img>
                <p class="text-center title font-weight-semibold black--text mt-5">{{ $t('Alerts.Success') }}</p>
                <p class="text-center black--text">{{ $t('Alerts.DeleteCalendarSuccess') }}</p>
            </v-snackbar>
            <v-snackbar :timeout="2000" top v-model="isDeleteCalendarDatesSuccess" color="white">
                <v-img class="mx-auto trashcan" width="80" src="@/assets/icons/correct.png"></v-img>
                <p class="text-center title font-weight-semibold black--text mt-5">{{ $t('Alerts.Success') }}</p>
                <p class="text-center black--text">{{ $t('Alerts.DeleteCalendarSuccess') }}</p>
            </v-snackbar>
            <v-snackbar :timeout="2000" top v-model="isError" color="white">
                <v-img class="mx-auto trashcan" width="80" src="@/assets/icons/failed.png"></v-img>
                <p class="text-center title font-weight-semibold black--text mt-5">{{ $t('Alerts.Error') }}</p>
                <p class="text-center black--text">{{ $t('Alerts.ErrorMessage') }}</p>
            </v-snackbar>
        </v-sheet>

        <v-app-bar height="80" absolute color="white" elevate-on-scroll scroll-target="#scrolling-techniques-7">
            <v-container class="d-flex">
                <v-toolbar-title>
                    <p
                        class="d-none d-md-flex d-lg-flex d-xl-flex text-h5 font-weight-semibold black--text mt-1"
                        v-if="activeTab == 0">
                        {{ $t('Routes') }}
                    </p>
                    <p
                        class="d-none d-md-flex d-lg-flex d-xl-flex text-h5 font-weight-semibold black--text mt-1"
                        v-else-if="activeTab == 1">
                        {{ $t('Trips') }}
                    </p>
                    <p
                        class="d-none d-md-flex d-lg-flex d-xl-flex text-h5 font-weight-semibold black--text mt-1"
                        v-else-if="activeTab == 2">
                        {{ $t('Calendar') }}
                    </p>
                    <p
                        class="text-h6 font-weight-semibold black--text mt-1 d-flex d-xs-flex d-sm-flex d-md-none"
                        v-if="activeTab == 0">
                        {{ $t('Routes') }}
                    </p>

                    <p
                        class="text-h6 font-weight-semibold black--text mt-1 d-flex d-xs-flex d-sm-flex d-md-none"
                        v-else-if="activeTab == 1">
                        {{ $t('Trips') }}
                    </p>

                    <p
                        class="text-h6 font-weight-semibold black--text mt-1 d-flex d-xs-flex d-sm-flex d-md-none"
                        v-else-if="activeTab == 2">
                        {{ $t('Calendar') }}
                    </p>
                </v-toolbar-title>

                <v-spacer></v-spacer>
                <v-btn
                    @click="$router.push({ name: 'importRoutes' })"
                    color="primary"
                    class="mx-3"
                    style="position: relative; overflow: hidden">
                    <v-icon class="mr-2 d-none d-md-flex d-lg-flex d-xl-flex" color="white">
                        {{ icons.mdiUpload }}
                    </v-icon>
                    <v-icon class="d-flex d-xs-flex d-sm-flex d-md-none" color="white">
                        {{ icons.mdiUpload }}
                    </v-icon>
                    <span class="d-none d-md-flex d-lg-flex d-xl-flex">{{ $t('ImportRoutes') }}</span>
                </v-btn>

                <v-dialog
                    v-if="routesSelected.length > 0 && activeTab == 0"
                    transition="dialog-top-transition"
                    max-width="600">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn outlined color="error" v-bind="attrs" v-on="on">
                            <v-icon class="mr-2 d-none d-md-flex d-lg-flex d-xl-flex" color="error">
                                {{ icons.mdiTrashCanOutline }}
                            </v-icon>
                            <v-icon class="d-flex d-xs-flex d-sm-flex d-md-none" color="error">
                                {{ icons.mdiTrashCanOutline }}
                            </v-icon>
                            <span class="d-none d-md-flex d-lg-flex d-xl-flex">{{ $t('Buttons.Delete') }}</span>
                        </v-btn>
                    </template>
                    <template v-slot:default="dialog">
                        <v-img class="mx-auto trashcan" width="80" src="@/assets/icons/delete.png"></v-img>

                        <v-card>
                            <v-card-text class="text-center">
                                <p class="title font-weight-semibold black--text mt-12">{{ $t('Alerts.Sure') }}</p>
                            </v-card-text>
                            <v-card-text class="text-center">
                                <p v-if="routesSelected.length === 1" class="body-2">
                                    {{ $t('Alerts.SureDeleteRoute') }}
                                    <strong class="black--text">{{
                                        routesSelected.map((entry) => entry.long_name).join(', ')
                                    }}</strong>
                                    ?<br />
                                    {{ $t('Alerts.Irreversible') }}
                                </p>
                                <p v-else class="body-2">
                                    {{ $t('Alerts.SureDeleteRoute') }}
                                    <strong class="black--text">{{
                                        routesSelected.map((entry) => entry.long_name).join(', ')
                                    }}</strong>
                                    ?<br />
                                    {{ $t('Alerts.Irreversible') }}
                                </p>
                            </v-card-text>

                            <v-card-actions class="justify-end mt-4">
                                <v-btn :loading="isLoading" @click="deleteSelectedRoutes" color="error" class="px-5">{{
                                    $t('Buttons.Delete')
                                }}</v-btn>
                                <v-btn :loading="isLoading" text @click="dialog.value = false">{{
                                    $t('Buttons.Cancel')
                                }}</v-btn>
                            </v-card-actions>
                        </v-card>
                    </template>
                </v-dialog>

                <v-dialog
                    v-else-if="tripsSelected.length > 0 && activeTab == 1 && tripsActiveTab == 0"
                    transition="dialog-top-transition"
                    max-width="600">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn outlined color="error" v-bind="attrs" v-on="on">
                            <v-icon class="mr-2 d-none d-md-flex d-lg-flex d-xl-flex" color="error">
                                {{ icons.mdiTrashCanOutline }}
                            </v-icon>
                            <v-icon class="d-flex d-xs-flex d-sm-flex d-md-none" color="error">
                                {{ icons.mdiTrashCanOutline }}
                            </v-icon>
                            <span class="d-none d-md-flex d-lg-flex d-xl-flex">{{ $t('Buttons.Delete') }}</span>
                        </v-btn>
                    </template>
                    <template v-slot:default="dialog">
                        <v-img class="mx-auto trashcan" width="80" src="@/assets/icons/delete.png"></v-img>

                        <v-card>
                            <v-card-text class="text-center">
                                <p class="title font-weight-semibold black--text mt-12">{{ $t('Alerts.Sure') }}</p>
                            </v-card-text>
                            <v-card-text class="text-center">
                                <p v-if="tripsSelected.length === 1" class="body-2">
                                    {{ $t('Alerts.SureDeleteTrip') }}
                                    <strong class="black--text">{{
                                        tripsSelected.map((entry) => entry.short_name).join(', ')
                                    }}</strong>
                                    ?<br />
                                    {{ $t('Alerts.Irreversible') }}
                                </p>
                                <p v-else class="body-2">
                                    {{ $t('Alerts.SureDeleteTrip') }}
                                    <strong class="black--text">{{
                                        tripsSelected.map((entry) => entry.short_name).join(', ')
                                    }}</strong>
                                    ?<br />
                                    {{ $t('Alerts.Irreversible') }}
                                </p>
                            </v-card-text>

                            <v-card-actions class="justify-end mt-4">
                                <v-btn :loading="isLoading" @click="deleteSelectedTrips" color="error" class="px-5">{{
                                    $t('Buttons.Delete')
                                }}</v-btn>
                                <v-btn :loading="isLoading" text @click="dialog.value = false">{{
                                    $t('Buttons.Cancel')
                                }}</v-btn>
                            </v-card-actions>
                        </v-card>
                    </template>
                </v-dialog>

                <v-dialog
                    v-else-if="stopTimesSelected.length > 0 && activeTab == 1 && tripsActiveTab == 1"
                    transition="dialog-top-transition"
                    max-width="600">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn outlined color="error" v-bind="attrs" v-on="on">
                            <v-icon class="mr-2 d-none d-md-flex d-lg-flex d-xl-flex" color="error">
                                {{ icons.mdiTrashCanOutline }}
                            </v-icon>
                            <v-icon class="d-flex d-xs-flex d-sm-flex d-md-none" color="error">
                                {{ icons.mdiTrashCanOutline }}
                            </v-icon>
                            <span class="d-none d-md-flex d-lg-flex d-xl-flex">{{ $t('Buttons.Delete') }}</span>
                        </v-btn>
                    </template>
                    <template v-slot:default="dialog">
                        <v-img class="mx-auto trashcan" width="80" src="@/assets/icons/delete.png"></v-img>

                        <v-card>
                            <v-card-text class="text-center">
                                <p class="title font-weight-semibold black--text mt-12">{{ $t('Alerts.Sure') }}</p>
                            </v-card-text>
                            <v-card-text class="text-center">
                                <p v-if="stopTimesSelected.length === 1" class="body-2">
                                    {{ $t('Alerts.SureDeleteStopTime') }}
                                    <strong class="black--text">{{
                                        stopTimesSelected.map((entry) => entry.headsign).join(', ')
                                    }}</strong>
                                    ?<br />
                                    {{ $t('Alerts.Irreversible') }}
                                </p>
                                <p v-else class="body-2">
                                    {{ $t('Alerts.SureDeleteStopTime') }}
                                    <strong class="black--text">{{
                                        stopTimesSelected.map((entry) => entry.headsign).join(', ')
                                    }}</strong>
                                    ?<br />
                                    {{ $t('Alerts.Irreversible') }}
                                </p>
                            </v-card-text>

                            <v-card-actions class="justify-end mt-4">
                                <v-btn
                                    :loading="isLoading"
                                    @click="deleteSelectedStopTimes"
                                    color="error"
                                    class="px-5"
                                    >{{ $t('Buttons.Delete') }}</v-btn
                                >
                                <v-btn :loading="isLoading" text @click="dialog.value = false">{{
                                    $t('Buttons.Cancel')
                                }}</v-btn>
                            </v-card-actions>
                        </v-card>
                    </template>
                </v-dialog>

                <v-dialog
                    v-else-if="
                        calendarsSelected.length > 0 && activeTab == 1 && tripsActiveTab == 2 && calendarActiveTab == 0
                    "
                    transition="dialog-top-transition"
                    max-width="600">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn outlined color="error" v-bind="attrs" v-on="on">
                            <v-icon class="mr-2 d-none d-md-flex d-lg-flex d-xl-flex" color="error">
                                {{ icons.mdiTrashCanOutline }}
                            </v-icon>
                            <v-icon class="d-flex d-xs-flex d-sm-flex d-md-none" color="error">
                                {{ icons.mdiTrashCanOutline }}
                            </v-icon>
                            <span class="d-none d-md-flex d-lg-flex d-xl-flex">{{ $t('Buttons.Delete') }}</span>
                        </v-btn>
                    </template>
                    <template v-slot:default="dialog">
                        <v-img class="mx-auto trashcan" width="80" src="@/assets/icons/delete.png"></v-img>

                        <v-card>
                            <v-card-text class="text-center">
                                <p class="title font-weight-semibold black--text mt-12">{{ $t('Alerts.Sure') }}</p>
                            </v-card-text>
                            <v-card-text class="text-center">
                                <p v-if="calendarsSelected.length === 1" class="body-2">
                                    {{ $t('Alerts.SureDeleteCalendar') }}
                                    <strong class="black--text">{{
                                        calendarsSelected.map((entry) => entry.start_date).join(', ')
                                    }}</strong>
                                    ?<br />
                                    {{ $t('Alerts.Irreversible') }}
                                </p>
                                <p v-else class="body-2">
                                    {{ $t('Alerts.SureDeleteCalendar') }}
                                    <strong class="black--text">{{
                                        calendarsSelected.map((entry) => entry.start_date).join(', ')
                                    }}</strong>
                                    ?<br />
                                    {{ $t('Alerts.Irreversible') }}
                                </p>
                            </v-card-text>

                            <v-card-actions class="justify-end mt-4">
                                <v-btn
                                    :loading="isLoading"
                                    @click="deleteSelectedCalendars"
                                    color="error"
                                    class="px-5"
                                    >{{ $t('Buttons.Delete') }}</v-btn
                                >
                                <v-btn :loading="isLoading" text @click="dialog.value = false">{{
                                    $t('Buttons.Cancel')
                                }}</v-btn>
                            </v-card-actions>
                        </v-card>
                    </template>
                </v-dialog>
                <v-dialog
                    v-else-if="
                        calendarDatesSelected.length > 0 &&
                        activeTab == 1 &&
                        tripsActiveTab == 2 &&
                        calendarActiveTab == 1
                    "
                    transition="dialog-top-transition"
                    max-width="600">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn outlined color="error" v-bind="attrs" v-on="on">
                            <v-icon class="mr-2 d-none d-md-flex d-lg-flex d-xl-flex" color="error">
                                {{ icons.mdiTrashCanOutline }}
                            </v-icon>
                            <v-icon class="d-flex d-xs-flex d-sm-flex d-md-none" color="error">
                                {{ icons.mdiTrashCanOutline }}
                            </v-icon>
                            <span class="d-none d-md-flex d-lg-flex d-xl-flex">{{ $t('Buttons.Delete') }}</span>
                        </v-btn>
                    </template>
                    <template v-slot:default="dialog">
                        <v-img class="mx-auto trashcan" width="80" src="@/assets/icons/delete.png"></v-img>

                        <v-card>
                            <v-card-text class="text-center">
                                <p class="title font-weight-semibold black--text mt-12">{{ $t('Alerts.Sure') }}</p>
                            </v-card-text>
                            <v-card-text class="text-center">
                                <p v-if="calendarDatesSelected.length === 1" class="body-2">
                                    {{ $t('Alerts.SureDeleteCalendarDate') }}
                                    <strong class="black--text">{{
                                        calendarDatesSelected.map((entry) => entry.date).join(', ')
                                    }}</strong>
                                    ?<br />
                                    {{ $t('Alerts.Irreversible') }}
                                </p>
                                <p v-else class="body-2">
                                    {{ $t('Alerts.SureDeleteCalendarDate') }}
                                    <strong class="black--text">{{
                                        calendarDatesSelected.map((entry) => entry.date).join(', ')
                                    }}</strong>
                                    ?<br />
                                    {{ $t('Alerts.Irreversible') }}
                                </p>
                            </v-card-text>

                            <v-card-actions class="justify-end mt-4">
                                <v-btn
                                    :loading="isLoading"
                                    @click="deleteSelectedCalendarDates"
                                    color="error"
                                    class="px-5"
                                    >{{ $t('Buttons.Delete') }}</v-btn
                                >
                                <v-btn :loading="isLoading" text @click="dialog.value = false">{{
                                    $t('Buttons.Cancel')
                                }}</v-btn>
                            </v-card-actions>
                        </v-card>
                    </template>
                </v-dialog>
            </v-container>
        </v-app-bar>
    </section>
</template>

<script>
    import RouteList from '@/views/route/routes-list/RoutesList.vue';
    import TripList from '@/views/route/trips-list/TripsList.vue';
    import RouteFilters from '@/views/route/route-filters/RouteFilters.vue';
    import TripFilters from '@/views/route/trip-filters/TripFilters.vue';
    import StopTimesFilters from '@/views/route/stop-times-filter/StopTimesFilter.vue';
    import CalendarsFilters from '@/views/route/calendar/calendar-filter/CalendarFilter.vue';
    import CalendarDatesFilters from '@/views/route/calendar/calendar-dates-filter/CalendarDatesFilter.vue';

    import {
        mdiAccountOutline,
        mdiChevronRight,
        mdiDeleteOutline,
        mdiDotsVertical,
        mdiExportVariant,
        mdiFileDocumentOutline,
        mdiFilterVariant,
        mdiMagnify,
        mdiPlus,
        mdiSquareEditOutline,
        mdiUpload,
        mdiTrashCanOutline,
    } from '@mdi/js';
    import useRoutesList from './routes-list/useRoutesList';
    export default {
        setup() {
            const { fetchRoutesList } = useRoutesList();
            return {
                icons: {
                    mdiSquareEditOutline,
                    mdiFileDocumentOutline,
                    mdiDotsVertical,
                    mdiDeleteOutline,
                    mdiPlus,
                    mdiExportVariant,
                    mdiAccountOutline,
                    mdiMagnify,
                    mdiChevronRight,
                    mdiFilterVariant,
                    mdiUpload,
                    mdiTrashCanOutline,
                },

                fetchRoutesList,
            };
        },
        components: {
            RouteList,
            TripList,
            RouteFilters,
            TripFilters,
            StopTimesFilters,
            CalendarsFilters,
            CalendarDatesFilters,
        },

        data() {
            return {
                activeTab: 0,
                routesSelected: [],
                tripsSelected: [],
                stopTimesSelected: [],
                calendarsSelected: [],
                calendarDatesSelected: [],
                isDeleteRouteSuccess: false,
                isDeleteTripSuccess: false,
                isDeleteStopTimeSuccess: false,
                isDeleteCalendarSuccess: false,
                isDeleteCalendarDatesSuccess: false,
                isError: false,
                isLoading: false,
                tripsActiveTab: 0,
                calendarActiveTab: 0,

                routesDeletedFinished: false,
                tripsDeletedFinished: false,
                stopTimesDeletedFinished: false,
                calendarsDeletedFinished: false,
                calendarsDateDeletedFinished: false,

                isRouteFiltersActive: false,
                fetchFilteredRoutes: false,

                isTripFiltersActive: false,
                fetchFilteredTrips: false,

                isStopTimeFiltersActive: false,
                fetchFilteredStopTimes: false,

                isCalendarFiltersActive: false,
                fetchFilteredCalendars: false,

                isCalendarDatesFiltersActive: false,
                fetchFilteredCalendarDates: false,
            };
        },

        methods: {
            getRoutesSelected(routes) {
                this.routesSelected = routes;
            },
            getTripsSelected(trips) {
                this.tripsSelected = trips;
            },

            getStopTimesSelected(stopTimes) {
                this.stopTimesSelected = stopTimes;
            },

            getTripsSelectedTab(tab) {
                this.tripsActiveTab = tab;
            },

            getCalendarsSelected(calendars) {
                this.calendarsSelected = calendars;
            },

            getCalendarDatesSelected(calendarDates) {
                this.calendarDatesSelected = calendarDates;
            },

            getRouteFilter(bol) {
                this.isRouteFiltersActive = bol;
                this.fetchFilteredRoutes = false;
            },

            getTripFilter(bol) {
                this.isTripFiltersActive = bol;
                this.fetchFilteredTrips = false;
            },
            getStopTimeFilter(bol) {
                this.isStopTimeFiltersActive = bol;
                this.fetchFilteredStopTimes = false;
            },

            getCalendarFilter(bol) {
                this.isCalendarFiltersActive = bol;
                this.fetchFilteredCalendars = false;
            },

            getCalendarDatesFilter(bol) {
                this.isCalendarDatesFiltersActive = bol;
                this.fetchFilteredCalendarDates = false;
            },

            deleteSelectedRoutes() {
                this.isLoading = true;
                this.routesDeletedFinished = false;
                this.$store
                    .dispatch('app-routes/deleteManyRoutes', {
                        where: {
                            id: { $in: this.routesSelected.map((item) => item.id) },
                        },
                    })
                    .then((response) => {
                        this.isDeleteRouteSuccess = true;
                        this.$store.commit('app-routes/setIsDeleteRoute', true);
                        this.routesDeletedFinished = true;

                        setTimeout(() => {
                            this.isLoading = false;
                        }, 2000);
                    })
                    .catch((error) => {
                        this.isError = true;
                        this.routesDeletedFinished = false;

                        setTimeout(() => {
                            this.isLoading = false;
                        }, 1000);
                    })
                    .finally(() => {
                        this.routesSelected = [];
                    });
            },

            deleteSelectedTrips() {
                this.isLoading = true;
                this.tripsDeletedFinished = false;
                this.$store
                    .dispatch('app-routes/deleteManyTrips', {
                        where: {
                            id: { $in: this.tripsSelected.map((item) => item.id) },
                        },
                    })
                    .then((response) => {
                        this.isDeleteTripSuccess = true;
                        this.$store.commit('app-routes/setIsDeleteTrip', true);
                        this.tripsDeletedFinished = true;

                        setTimeout(() => {
                            this.isLoading = false;
                        }, 2000);
                    })
                    .catch((error) => {
                        this.isError = true;
                        this.tripsDeletedFinished = false;

                        setTimeout(() => {
                            this.isLoading = false;
                        }, 1000);
                    })
                    .finally(() => {
                        this.tripsSelected = [];
                    });
            },

            deleteSelectedStopTimes() {
                this.isLoading = true;
                this.stopTimesDeletedFinished = false;
                this.$store
                    .dispatch('app-routes/deleteManyStopTimes', {
                        where: {
                            id: { $in: this.stopTimesSelected.map((item) => item.id) },
                        },
                    })
                    .then((response) => {
                        this.isDeleteStopTimeSuccess = true;
                        this.$store.commit('app-routes/setIsDeleteStopTimes', true);
                        this.stopTimesDeletedFinished = true;

                        setTimeout(() => {
                            this.isLoading = false;
                        }, 2000);
                    })
                    .catch((error) => {
                        this.isError = true;
                        this.stopTimesDeletedFinished = false;

                        setTimeout(() => {
                            this.isLoading = false;
                        }, 1000);
                    })
                    .finally(() => {
                        this.stopTimesSelected = [];
                    });
            },

            getRoutesList() {
                this.fetchFilteredRoutes = true;
            },
            getTripsList() {
                this.fetchFilteredTrips = true;
            },
            getStopTimesList() {
                this.fetchFilteredStopTimes = true;
            },
            getCalendarsList() {
                this.fetchFilteredCalendars = true;
            },

            getCalendarDatesList() {
                this.fetchFilteredCalendarDates = true;
            },

            deleteSelectedCalendars() {
                this.isLoading = true;
                this.calendarsDeletedFinished = false;
                this.$store
                    .dispatch('app-routes/deleteManyCalendars', {
                        where: {
                            id: { $in: this.calendarsSelected.map((item) => item.id) },
                        },
                    })
                    .then((response) => {
                        this.isDeleteCalendarSuccess = true;
                        this.$store.commit('app-routes/setIsDeleteCalendar', true);
                        this.calendarsDeletedFinished = true;

                        setTimeout(() => {
                            this.isLoading = false;
                        }, 2000);
                    })
                    .catch((error) => {
                        this.isError = true;
                        this.calendarsDeletedFinished = false;

                        setTimeout(() => {
                            this.isLoading = false;
                        }, 1000);
                    })
                    .finally(() => {
                        this.calendarsSelected = [];
                    });
            },

            deleteSelectedCalendarDates() {
                this.isLoading = true;
                this.calendarsDateDeletedFinished = false;
                this.$store
                    .dispatch('app-routes/deleteManyCalendarsDate', {
                        where: {
                            id: { $in: this.calendarDatesSelected.map((item) => item.id) },
                        },
                    })
                    .then((response) => {
                        this.isDeleteCalendarDatesSuccess = true;
                        this.$store.commit('app-routes/setIsDeleteCalendarDate', true);
                        this.calendarsDateDeletedFinished = true;

                        setTimeout(() => {
                            this.isLoading = false;
                        }, 2000);
                    })
                    .catch((error) => {
                        this.isError = true;
                        this.calendarsDateDeletedFinished = false;

                        setTimeout(() => {
                            this.isLoading = false;
                        }, 1000);
                    })
                    .finally(() => {
                        this.calendarDatesSelected = [];
                    });
            },

            getCalendarSelectedTab(tab) {
                this.calendarActiveTab = tab;
            },

            getMainActiveTab(tab) {
                this.activeTab = tab;
            },
        },
    };
</script>

<style lang="scss" scoped>
    .v-application.theme--light .v-tabs:not(.v-tabs--vertical) {
        box-shadow: 0 0px 0px 0px rgb(94 86 105 / 42%) !important;
    }
</style>
