<template>
    <v-navigation-drawer
        :value="isFiltersActive"
        style="z-index: 10"
        temporary
        touchless
        :right="!$vuetify.rtl"
        :width="$vuetify.breakpoint.smAndUp ? 350 : '100%'"
        app
        @input="(val) => $emit('update:is-add-new-user-sidebar-active', val)">
        <v-card height="100%">
            <div class="drawer-header d-flex align-center mb-4">
                <span class="font-weight-semibold text-base text--primary">{{ $t('Filters') }}</span>
                <v-spacer></v-spacer>
                <v-btn color="primary" icon small @click="$emit('update:is-add-new-user-sidebar-active', false)">
                    <v-icon size="22">
                        {{ mdiClose }}
                    </v-icon>
                </v-btn>
            </div>

            <v-card-text>
                <v-btn @click="deleteFilters" expanded class="d-flex ml-auto text-capitalize" text color="error">
                    {{ $t('DeleteFilters') }}
                    <v-icon right>
                        {{ mdiCloseBoxOutline }}
                    </v-icon>
                </v-btn>
                <div class="mt-4">
                    <v-menu
                        ref="startDateMenu"
                        v-model="startDateMenu"
                        :close-on-content-click="false"
                        :return-value.sync="query.filters.start_date.$gte"
                        transition="scale-transition"
                        offset-y
                        min-width="auto">
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                v-model="query.filters.start_date.$gte"
                                :label="$t('StartDate')"
                                readonly
                                filled
                                hide-details="auto"
                                shaped
                                v-bind="attrs"
                                v-on="on"></v-text-field>
                        </template>
                        <v-date-picker v-model="query.filters.start_date.$gte" no-title scrollable>
                            <v-spacer></v-spacer>
                            <v-btn text color="primary" @click="startDateMenu = false"> Cancel </v-btn>
                            <v-btn
                                text
                                color="primary"
                                @click="$refs.startDateMenu.save(query.filters.start_date.$gte)">
                                OK
                            </v-btn>
                        </v-date-picker>
                    </v-menu>
                    <v-menu
                        ref="endDateMenu"
                        v-model="endDateMenu"
                        :close-on-content-click="false"
                        :return-value.sync="query.filters.end_date.$lte"
                        transition="scale-transition"
                        offset-y
                        min-width="auto">
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                v-model="query.filters.end_date.$lte"
                                :label="$t('EndDate')"
                                readonly
                                filled
                                hide-details="auto"
                                shaped
                                v-bind="attrs"
                                v-on="on"
                                class="mt-6"></v-text-field>
                        </template>
                        <v-date-picker v-model="query.filters.end_date.$lte" no-title scrollable>
                            <v-spacer></v-spacer>
                            <v-btn text color="primary" @click="endDateMenu = false"> Cancel </v-btn>
                            <v-btn text color="primary" @click="$refs.endDateMenu.save(query.filters.end_date.$lte)">
                                OK
                            </v-btn>
                        </v-date-picker>
                    </v-menu>

                    <div class="mt-6">
                        <v-btn @click="applyFilters" color="primary" class="me-3" type="submit">
                            {{ $t('Buttons.Filter') }}
                        </v-btn>
                        <v-btn
                            @click="$emit('update:is-add-new-user-sidebar-active', false)"
                            color="secondary"
                            outlined>
                            {{ $t('Buttons.Cancel') }}
                        </v-btn>
                    </div>
                </div>
            </v-card-text>
        </v-card>
    </v-navigation-drawer>
</template>

<script>
    import { mdiClose, mdiCloseBoxOutline } from '@mdi/js';
    import routeStoreModule from '@/views/route/routeStoreModule';

    export default {
        data() {
            return {
                routeStoreModule,
                query: {},
                startDateMenu: false,
                endDateMenu: false,
            };
        },
        model: {
            prop: 'isFiltersActive',
            event: 'update:is-add-new-user-sidebar-active',
        },
        props: {
            isFiltersActive: {
                type: Boolean,
                required: true,
            },
        },

        setup() {
            return {
                mdiClose,
                mdiCloseBoxOutline,
            };
        },
        created() {
            this.query = this.$store.getters['app-routes/getCalendarsQuery'];
        },
        methods: {
            applyFilters() {
                this.query.pagination.page = 1;
                this.$store.commit('app-routes/setCalendarQuery', this.query);
                this.$emit('setQuery');
                this.$emit('update:is-add-new-user-sidebar-active', false);
            },
            deleteFilters() {
                this.query.filters.start_date = {};
                this.query.filters.end_date = {};
            },
        },
    };
</script>
