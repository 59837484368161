import store from '@/store';
import _ from 'lodash';
import { i18n } from '@/plugins/i18n';
import { ref, watch } from '@vue/composition-api';

export default function useStopTimesList() {
    const stopListTable = ref([]);

    const stopsTableColumns = [
        { text: i18n.t('Arrival'), value: 'arrival_time', class: 'text-uppercase', width: '8rem' },
        { text: i18n.t('Departure'), value: 'departure_time', class: 'text-uppercase', width: '8rem' },
        { text: i18n.t('Stop'), value: 'stop.name', class: 'text-uppercase', width: '12rem' },
        { text: i18n.t('Headsign'), value: 'headsign', class: 'text-uppercase', width: '12rem' },
        { text: i18n.t('PickupType'), value: 'pickup_type', class: 'text-uppercase', width: '12rem' },
        { text: i18n.t('DropOffType'), value: 'drop_off_type', class: 'text-uppercase', width: '12rem' },

        {
            text: '',
            value: 'actions',
            width: '5%',
        },
    ];

    const searchQuery = ref('');
    const selectedRows = ref([]);
    const loading = ref(false);
    const totalStopListTable = ref(0);
    const oldSearch = ref('');
    const oldDepartureFilter = ref('');
    const oldArrivalFilter = ref('');
    const oldStopFilter = ref('');
    const oldPickupTypeFilter = ref('');
    const oldDropOffTypeFilter = ref('');
    const options = ref({
        sortBy: ['id'],
        sortDesc: [true],
    });

    const fecthStopTimesList = () => {
        let verifyQuery = store.getters['app-routes/getStopTimesQuery'];
        let isDeleteStopTime = store.getters['app-routes/getIsDeleteStopTimes'];
        store
            .dispatch('app-routes/fetchStopTimesList', {})
            .then((response) => {
                if (isDeleteStopTime) {
                    stopListTable.value = [];
                    stopListTable.value.push(...response.data.data);
                    store.commit('app-routes/setIsDeleteStopTimes', true);
                } else if (
                    oldSearch.value == verifyQuery.filters.$or[0].departure_time.$containsi &&
                    oldDepartureFilter.value == verifyQuery.filters.departure_time.$containsi &&
                    oldArrivalFilter.value == verifyQuery.filters.arrival_time.$containsi &&
                    oldStopFilter.value == verifyQuery.filters.stop.id.$containsi &&
                    oldPickupTypeFilter.value == verifyQuery.filters.pickup_type.$containsi &&
                    oldDropOffTypeFilter.value == verifyQuery.filters.drop_off_type.$containsi
                ) {
                    stopListTable.value = [];
                    stopListTable.value.push(...response.data.data);
                } else {
                    oldSearch.value = verifyQuery.filters.$or[0].departure_time.$containsi;
                    oldDepartureFilter.value = verifyQuery.filters.departure_time.$containsi;
                    oldArrivalFilter.value = verifyQuery.filters.arrival_time.$containsi;
                    oldStopFilter.value = verifyQuery.filters.stop.id.$containsi;
                    oldPickupTypeFilter.value = verifyQuery.filters.pickup_type.$containsi;
                    oldDropOffTypeFilter.value = verifyQuery.filters.drop_off_type.$containsi;
                    stopListTable.value = [];
                    stopListTable.value.push(...response.data.data);
                }

                totalStopListTable.value = response.data.meta.pagination.total;
                loading.value = false;
            })
            .catch((error) => {
                throw error;
            });
    };

    watch([searchQuery, options], () => {
        loading.value = true;
        selectedRows.value = [];
        fecthStopTimesList();
    });

    return {
        stopsTableColumns,
        stopListTable,
        totalStopListTable,
        loading,
        selectedRows,
        searchQuery,
        options,
        fecthStopTimesList,
    };
}
